<template>
    <MultiSelect :id="id"
                 v-model="selectedCompany"
                 :options="options"
                 :internal-search="false"
                 :options-limit="30"
                 :loading="loading"
                 :placeholder="placeholder"
                 :option-height="optionHeight"
                 :clear-on-select="false"
                 :preserve-search="true"
                 track-by="companyId"
                 label="companyName"
                 @search-change="searchChanged">
        <template #option="{ option: company }">
            <div class="flex items-center gap-x-2">
                {{ company.companyName }}
                <span v-if="company.certified" title="EcoClaim Certified">
                    <component :is="iconComponent" icon="logo-icon" class="w-4 h-4" />
                </span>
            </div>
            <div v-if="'ALL' !== company.companyId" class="mt-1 text-xs opacity-75 leading-5">
                <div v-if="showCompanyTypes">{{ company.companyTypeNames }}</div>
                <div>{{ company.addressString }}</div>
            </div>
        </template>
        <template #noResult>
            No companies could be found for your search.
        </template>
        <!-- this should really never happen, but just in case we'll customize the message -->
        <template #noOptions>
            No companies are available.
        </template>
    </MultiSelect>
</template>

<script setup>
import MultiSelect from 'vue-multiselect';
import { computed } from 'vue';
import { companyTypes } from '@/common/company_types';

const emit = defineEmits([ 'update:modelValue', 'search-changed' ]);

const props = defineProps({
    modelValue: {
        type: String,
        default: null,
    },
    companies: {
        type: Array,
        required: true,
    },
    showCompanyTypes: {
        type: Boolean,
        default: true,
    },
    loading: {
        type: Boolean,
        default: false,
    },
    placeholder: {
        type: String,
        default: null,
    },
    iconComponent: {
        type: String,
        default: 'PublicIcon',
    },
    id: {
        type: String,
        required: true,
    },
});

// we need this instead of defineModel as multiselect emits the whole object, but we only want the ID
const selectedCompany = computed({
    get () {
        return props.companies.find((c) => c.companyId === props.modelValue);
    },
    set (value) {
        emit('update:modelValue', value?.companyId || null);
    },
});

const options = computed(() => {
    const companies = [];

    companies.push(...props.companies.map((c) => {
        if ('ALL' === c.companyId) {
            return c;
        }

        return {
            ...c,
            addressString:
                `${c.address.line1}, ${c.address.city}, ${c.address.province.abbreviation || c.address.province.name}`
                + (showCountryName.value ? `, ${c.address.country.name}` : ''),
            companyTypeNames: c?.companyTypes.map((ct) => companyTypes[ct]).join(', '),
        };
    }));

    return companies;
});

const showCountryName = computed(() => {
    const countries = new Set();

    props.companies
        .filter((company) => 'ALL' !== company.companyId)
        .reduce(
            (countries, company) => countries.add(company.address.country.abbreviation),
            countries,
        );

    return countries.size > 1;
});

const optionHeight = computed(() => {
    return props.showCompanyTypes ? 84 : 64;
});

const placeholder = computed(() => {
    if (props.placeholder) {
        return props.placeholder;
    }

    return props.loading ? 'Loading companies…' : '– Select a company –';
});

const searchChanged = (search) => {
    emit('search-changed', search);
};
</script>
